import React from "react";

const Resume = () => {
  return (
    <div>
      <div className="container px-6 py-12 mx-auto mb-10 mt-10">
        <h1 className="text-2xl font-bold text-center mb-5 text-purple-500 dark:text-purple-400">
          Download My Resume
        </h1>
        <p className="font-normal text-lg text-center text-gray-500 dark:text-gray-400 m-auto w-full sm:w-2/3 md:1/2">
          Here, you can include a brief introduction about yourself and what the
          resume represents. For example: "I am a passionate and experienced
          software developer with a strong background in web and mobile
          development. My resume showcases the skills, projects, and experience
          that I bring to the table. Whether you're looking for expertise in
          React, Node.js, Android development, or backend technologies, my
          resume provides a comprehensive view of my professional journey.
          Download my resume to learn more about how I can contribute to your
          next project.
        </p>
        <iframe
          className="w-full mt-10"
          width="600"
          height="800"
          src="/Assets/Prince_Resume_August_2024.pdf"
        ></iframe>
      </div>
    </div>
  );
};

export default Resume;
