import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { baseUrl } from "../../App";
import ReactLoading from "react-loading";
import { RWebShare } from "react-web-share";

const FullArtical = () => {
  // Getting the Article Id from the Params
  const { articleId } = useParams();

  // Store the Data in State
  const [fullArtical, setFullArticles] = useState({});
  const [loading, setLoading] = useState(true);

  // Make the Function to get the Data for the Full Articles
  const getFullArticles = async () => {
    try {
      const url = `${baseUrl}/api/v1/blog/get/${articleId}`;
      await axios
        .get(url, {
          headers: {
            "Content-Type": "appllication/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          // console.log("Response while getting the Full Artilces ", response);
          setFullArticles(response.data.data);
        })
        .catch((error) => {
          console.log("Error while Getting Full Articles Values", error);
        });
    } catch (error) {
      console.log("Error while getting the Full Articles ", error);
    } finally {
      setLoading(false);
    }
  };

  // Calling the Function using the useEffect
  useEffect(() => {
    getFullArticles();
  }, []);

  return (
    // This is for Show details of the Articles
    <div className="">
      {loading ? (
        <div
          role="status"
          className="flex gap-3 flex-col items-center justify-center mt-10"
        >
          <ReactLoading
            type="spinningBubbles"
            color="#A352F5"
            height={100}
            width={100}
          />
          <span class="text-center dark:text-white text-lg">
            Loading Full Article...
          </span>
        </div>
      ) : (
        <div className="w-full sm:w-4/5 md:w-2/3 lg:w-1/2 px-6 py-12 pt-10 pb-10 mx-auto text-gray-700">
          <h1 className="text-3xl font-bold text-start text-black dark:text-white">
            {fullArtical.blogName}
          </h1>
          <img
            className="rounded-2xl w-full h-full mt-10 mb-10 object-cover"
            src={fullArtical.blogImage}
            alt=""
          />
          <div className="flex justify-end">
            <RWebShare
              data={{
                text: "Article",
                url: `https://princesahni.com/fullarticle/${fullArtical._id}`,
                title: `${fullArtical.blogName}`,
              }}
            >
              <div className="flex cursor-pointer gap-2 items-center bg-purple-50 dark:bg-gray-800 p-2 rounded-lg max-w-fit">
                <p className="dark:text-purple-400 font-semibold text-purple-400">
                  Share this article
                </p>
                <svg
                  class="w-6 h-6 text-purple-500 dark:text-purple-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-width="2"
                    d="M7.926 10.898 15 7.727m-7.074 5.39L15 16.29M8 12a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm12 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm0-11a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
                  />
                </svg>
              </div>
            </RWebShare>
          </div>

          <p className="mt-5 mb-5 text-lg font-medium tracking-tight text-justify dark:text-gray-200">
            {fullArtical.blogSmallDesc}
          </p>
          <p
            className="mb-5 mt-5 text-justify tracking-tighter text-lg font-normal dark:text-gray-400"
            dangerouslySetInnerHTML={{
              __html: fullArtical.blogFullDesc,
            }}
          />
          <p className="font-medium text-lg dark:text-white">
            Thanks for Reading{" "}
            <i className="text-red-500 fa-solid fa-heart"></i>
          </p>
        </div>
      )}
    </div>
  );
};

export default FullArtical;
