import axios from "axios";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { baseUrl } from "../../App";
import ReactLoading from "react-loading";
import { RWebShare } from "react-web-share";

const Course = () => {
  // Make the State for the Course
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch all the Course
  const getAllCourses = async () => {
    try {
      const url = `${baseUrl}/api/v1/course/get`;
      await axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          // console.log("Response while getting all the Coureses ", response);

          const sortedCourse = response.data.data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          setCourses(sortedCourse);
        })
        .catch((error) => {
          console.log("Error while getting all the Courses", error);
        });
    } catch (error) {
      console.log("Error while getting the Courses ", error);
    } finally {
      setLoading(false);
    }
  };

  // Call the useEffect hook for automatica calling
  useEffect(() => {
    getAllCourses();
  }, []);

  // For Date Format
  const formatDate = (isoString) => {
    const date = new Date(isoString);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = date.getUTCDate();
    const month = monthNames[date.getUTCMonth()]; // Get month name
    const year = date.getUTCFullYear();

    return `${day} ${month} ${year}`;
  };

  return (
    <div>
      <div className="container px-6 py-12 mx-auto mt-10 mb-10">
        <h1 className="text-center font-bold text-2xl mb-5 text-purple-500 dark:text-purple-400">
          Explore Our Programming Courses
        </h1>
        <p className="font-medium text-center w-2/3  m-auto mb-10 dark:text-gray-300">
          Dive into our range of structured programming courses designed to
          guide you through essential concepts and techniques. Each course
          offers practical insights and hands-on exercises to help you build and
          refine your coding skills.
        </p>

        {loading ? (
          <div
            role="status"
            className="flex gap-3 flex-col items-center justify-center mt-10"
          >
            <ReactLoading
              type="spinningBubbles"
              color="#A352F5"
              height={100}
              width={100}
            />
            <span class="text-center dark:text-white text-lg">Loading...</span>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {courses.map((item) => (
              <NavLink to={`/course/${item._id}`}>
                <div
                  key={item._id}
                  className="bg-purple-50 dark:bg-gray-800 rounded-lg  flex flex-col p-2"
                >
                  <img
                    className="h-48 object-cover rounded-lg"
                    src={item.courseImage}
                    alt="Course Banner Images"
                  />
                  <div className="mt-2 flex flex-col h-full justify-between">
                    <h3 className="font-semibold text-xl text-purple-500 dark:text-purple-400">
                      {item.title}
                    </h3>

                    <p className="font-normal hover:text-purple-500 mb-2 dark:text-gray-400">
                      {item.smallDesc.slice(0, 100)}...
                    </p>
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <img
                          src="https://res.cloudinary.com/sniperdocs/image/upload/v1723527053/Prince_Corp.__1_-removebg-preview_wrjvgk.png"
                          className="h-8 rounded-md"
                          alt="Flowbite Logo"
                        />
                        <p className="text-gray-500 font-semibold dark:text-gray-400">
                          {formatDate(item.createdAt)}
                        </p>
                      </div>

                      <RWebShare
                        data={{
                          text: "Share this Course",
                          url: `https://princesahni.com/course/${item._id}`,
                          title: `${item.title}`,
                        }}
                      >
                        <svg
                          class="w-6 h-6 text-purple-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-width="2"
                            d="M7.926 10.898 15 7.727m-7.074 5.39L15 16.29M8 12a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm12 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm0-11a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
                          />
                        </svg>
                      </RWebShare>
                    </div>
                  </div>
                </div>
              </NavLink>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Course;
