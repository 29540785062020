import React from "react";
import { NavLink } from "react-router-dom";

const service3 = () => {
  return (
    <div className="mt-40 mb-40">
      <div className="w-4/5 m-auto">
        <div className="mb-10 sm:w-2/3 w-full m-auto">
          <h2 className="text-purple-500 uppercase font-medium text-center mb-2">
            ONE-stop Learning Platform
          </h2>
          <h2 className="font-bold text-2xl text-center mb-4 dark:text-white">
            Discover a Wealth of Learning Resources to Elevate Your Skills
          </h2>
          <p className="text-center text-gray-500 dark:text-gray-400">
            Discover a range of resources to support your learning journey.
            Whether you prefer video tutorials, insightful articles, or
            structured courses, we have something for every learner.
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <div className="group relative rounded-lg">
            {/* Gradient border (visible only on hover) */}
            <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg blur opacity-0 transition duration-300 group-hover:opacity-100"></div>

            {/* Inner card */}
            <div className="relative bg-purple-100 dark:bg-gray-800 rounded-lg p-6">
              <svg
                className="w-20 h-20 text-red-600"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 4H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1Zm0 0-4 4m5 0H4m1 0 4-4m1 4 4-4m-4 7v6l4-3-4-3Z"
                />
              </svg>

              <h2 className="text-black font-semibold text-lg dark:text-white mt-4">
                Interactive YouTube Tutorials
              </h2>
              <p className="text-gray-500 dark:text-gray-400 mt-2">
                Watch and learn from our engaging video tutorials covering a
                variety of programming topics, designed to make complex concepts
                easier to understand.
              </p>
              <button className="p-2 text-sm mt-3 border-2 font-medium text-white bg-black rounded-md border-red-500">
                <NavLink to="/youtube">Youtube</NavLink>{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>

          <div className="group relative rounded-lg">
            {/* Gradient border (visible only on hover) */}
            <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg blur opacity-0 transition duration-300 group-hover:opacity-100"></div>

            {/* Inner card */}
            <div className="relative bg-purple-100 dark:bg-gray-800 rounded-lg p-6">
              <svg
                className="w-20 h-20 text-yellow-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="M8.737 8.737a21.49 21.49 0 0 1 3.308-2.724m0 0c3.063-2.026 5.99-2.641 7.331-1.3 1.827 1.828.026 6.591-4.023 10.64-4.049 4.049-8.812 5.85-10.64 4.023-1.33-1.33-.736-4.218 1.249-7.253m6.083-6.11c-3.063-2.026-5.99-2.641-7.331-1.3-1.827 1.828-.026 6.591 4.023 10.64m3.308-9.34a21.497 21.497 0 0 1 3.308 2.724m2.775 3.386c1.985 3.035 2.579 5.923 1.248 7.253-1.336 1.337-4.245.732-7.295-1.275M14 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
                />
              </svg>

              <h2 className="text-black font-semibold text-lg dark:text-white mt-4">
                In-Depth Coding Articles
              </h2>
              <p className="text-gray-500 dark:text-gray-400 mt-2">
                Read our comprehensive blog articles to stay updated on the
                latest coding trends and techniques, with practical advice and
                expert insights.
              </p>
              <button className="p-2 text-sm mt-3 border-2 font-medium text-white bg-black rounded-md border-yellow-500">
                <NavLink to="/articles">Articles</NavLink>{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>

          <div className="group relative rounded-lg">
            {/* Gradient border (visible only on hover) */}
            <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg blur opacity-0 transition duration-300 group-hover:opacity-100"></div>

            {/* Inner card */}
            <div className=" relative bg-purple-100 dark:bg-gray-800 rounded-lg p-6">
              <svg
                class="w-20 h-20 text-purple-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 5v14m-8-7h2m0 0h2m-2 0v2m0-2v-2m12 1h-6m6 4h-6M4 19h16c.5523 0 1-.4477 1-1V6c0-.55228-.4477-1-1-1H4c-.55228 0-1 .44772-1 1v12c0 .5523.44772 1 1 1Z"
                />
              </svg>

              <h2 className="text-black font-semibold text-lg dark:text-white mt-4">
                Structured Programming Courses
              </h2>
              <p className="text-gray-500 dark:text-gray-400 mt-2">
                Explore our detailed courses to build your programming skills
                from the ground up, with step-by-step guidance and practical
                exercises.
              </p>
              <button className="p-2 text-sm mt-3 border-2 font-medium text-white bg-black rounded-md  border-purple-500">
                <NavLink to="/courses">Courses</NavLink>{" "}
                <i class="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default service3;
