import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../../App";
import ReactLoading from "react-loading";

const WebApps = () => {
  // Make the State
  const [webApps, setWebApps] = useState([]);
  const [loading, setLoading] = useState(true);

  // Make the function to call the data
  const getAllWebApps = async () => {
    try {
      const url = `${baseUrl}/api/v1/webapps/get`;
      await axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          // console.log(
          //   "Response while getting All the Web Apps Data ",
          //   response
          // );

          const sortedWebsite = response.data.data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          setWebApps(sortedWebsite);
        })
        .catch((error) => {
          console.log("Error while getting the Web Apps Data ", error);
        });
    } catch (error) {
      console.log("Error while getting the Website App Data ", error);
    } finally {
      setLoading(false);
    }
  };

  // Automatically call the function
  useEffect(() => {
    getAllWebApps();
  }, []);

  return (
    <div>
      <div className="container px-6 py-12 mx-auto mt-10 mb-10">
        <h1 className="text-center font-bold text-2xl mb-5 text-purple-500 dark:text-purple-400">
          Showcase of Our Deployed Websites
        </h1>
        <p className="font-medium text-center w-2/3  m-auto mb-10 dark:text-gray-300">
          Explore the websites we've developed and deployed. Each project
          highlights our commitment to creating functional, user-friendly, and
          innovative web solutions tailored to meet diverse needs.
        </p>

        {loading ? (
          <div
            role="status"
            className="flex gap-3 flex-col items-center justify-center mt-10"
          >
            <ReactLoading
              type="spinningBubbles"
              color="#A352F5"
              height={100}
              width={100}
            />
            <span class="text-center dark:text-white text-lg">
              Loading All Websites...
            </span>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {webApps.map((item) => (
              <div
                key={item._id}
                className="bg-purple-50 dark:bg-gray-800 rounded-lg flex flex-col p-2"
              >
                <img
                  className="h-48 object-cover rounded-lg mb-2"
                  src={item.banner}
                  alt=""
                />
                <p className="font-bold text-lg mb-2 dark:text-white">
                  {item.name}
                </p>
                <p className="text-gray-500 dark:text-gray-400 mb-4">
                  {item.description.slice(0, 200)}...
                </p>

                <div className="flex items-center gap-2">
                  <a
                    href={item.link}
                    target="_blank"
                    className="bg-purple-600 text-sm p-2 text-white rounded-md font-medium"
                  >
                    Go To Link
                  </a>
                  <a
                    href={item.github}
                    target="_blank"
                    className="border border-gray-300 text-sm p-2 text-gray-700 dark:text-white rounded-md font-medium"
                  >
                    Github Code
                  </a>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default WebApps;
