import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { baseUrl } from "../../App";
import ReactLoading from "react-loading";

const Articals = () => {
  // Saving the Data which is fetched from the API
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  // Making the Function to call the DAa from the Api for the Articles
  const getArticles = async () => {
    try {
      const url = `${baseUrl}/api/v1/blog/get`;
      await axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {

          const sortedArticle = response.data.data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          setArticles(sortedArticle);
          // console.log("Response while getting the Articles", response);
        })
        .catch((error) => {
          console.log("Error while getting the Articles", error);
        });
    } catch (error) {
      console.log("Error while getting the Articles ", error);
    } finally {
      setLoading(false);
    }
  };

  // Calling the fnction with useEffect
  useEffect(() => {
    getArticles();
  }, []);

  // For Date Format
  const formatDate = (isoString) => {
    const date = new Date(isoString);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = date.getUTCDate();
    const month = monthNames[date.getUTCMonth()]; // Get month name
    const year = date.getUTCFullYear();

    return `${day} ${month} ${year}`;
  };

  return (
    // This Page is for Showing List of Articles
    <div>
      <div className="container px-6 py-12 mx-auto mb-10 mt-10">
        <h1 className="text-center font-bold text-2xl mb-5 text-purple-500 dark:text-purple-400">
          Read Our Latest Articles
        </h1>
        <p className="font-medium text-center w-2/3  m-auto mb-10 dark:text-gray-300">
          Stay informed with our collection of insightful articles on
          programming and technology. Each piece offers valuable perspectives
          and updates to help you stay current and deepen your understanding of
          the tech world.
        </p>

        {/* Loading Indicator  */}
        {loading ? (
          <div
            role="status"
            className="flex flex-col gap-3 items-center justify-center mt-10"
          >
            <ReactLoading
              type="spinningBubbles"
              color="#A352F5"
              height={100}
              width={100}
            />
            <span class="text-center dark:text-white text-lg">Loading...</span>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {/* One Blog Item  */}
            {articles.map((item) => (
              <div
                key={item._id}
                className="rounded-lg flex flex-col p-2 bg-purple-50 dark:bg-gray-800"
              >
                <NavLink to={`/fullarticle/${item._id}`}>
                  <img
                    className="rounded-lg h-48 object-cover mb-2 w-full"
                    src={item.blogImage}
                    alt=""
                  />
                  <p className="text-purple-500 dark:text-purple-400 font-medium mb-2 text-sm">
                    {formatDate(item.createdAt)}
                  </p>
                  <h2 className="text-xl font-bold mb-5 dark:text-white">
                    {item.blogName}
                  </h2>
                  <p className="text-gray-500 tracking-tight dark:text-gray-400 text-sm text-justify">
                    {item.blogSmallDesc.slice(0, 150)}
                  </p>
                </NavLink>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Articals;
