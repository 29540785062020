import React from 'react'
import { NavLink } from 'react-router-dom';

const PageNotFound = () => {
    return (
        <div>
            <section className="bg-white dark:bg-gray-900">
                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center flex items-center justify-center flex-col">
                        <img src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/404/404-computer.svg" alt="" />
                        <p className="mb-4 text-2xl tracking-tight font-bold text-purple-900 dark:text-white">404 Not Found</p>
                        <h1 className="mb-4 text-4xl tracking-tight font-bold text-black dark:text-purple-500">Whoops! That page doesn’t exist.</h1>
                        <p className="mb-4 text-lg font-medium text-gray-500 dark:text-gray-400">Sorry, we can't find that page. You'll find lots to explore on the home page. </p>
                        <NavLink to="/" className="inline-flex text-white bg-purple-600 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-purple-900 my-4">Back to Homepage</NavLink>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PageNotFound;