import axios from "axios";
import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { baseUrl } from "../../App";
import Cookies from "js-cookie";
import ReactLoading from "react-loading";


const Profile = () => {
  // make the state for the profile data
  const [profile, setProfile] = useState({});
  const [userExp, setUserExp] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const token = Cookies.get("user");
  console.log("Token from Profile ", token);

  // When Token not found then navigate to the Home Page
  useEffect(() => {
    console.log("Token ", token);
    if (!token) {
      navigate("/");
    }
  }, []);

  // Make the function call to get the profile userDetails Id
  const getProfileData = async () => {
    try {
      const url = `${baseUrl}/api/v1/auth/profile/${token}`;
      await axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          // console.log("Response while getting the User details Id", response);
          setProfile(response.data.data.userDetailId);
          setUserExp(response.data.data.userDetailId.userExp);
          const userProfilePic = response.data.data.userDetailId.profilePic;
          Cookies.set("userProfile", userProfilePic);
        })
        .catch((error) => {
          console.log("Error while getting the Profile ID", error);
        });
    } catch (error) {
      console.log("Error while getting the Profile ID", error);
    } finally {
      setLoading(false);
    }
  };

  // use the useeffect hook to call the function automatically
  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <div>
      <div className="bg-gray-100 dark:bg-gray-900">
        <div className="container mx-auto py-8">
          <div className="flex justify-end gap-2 p-4">
            {/* If Profile Data is already filled then don't show this button  */}
            {profile ? (
              <div></div>
            ) : (
              <div className="flex gap-2 bg-purple-600 rounded-md mb-4 p-2 items-center text-white font-semibold max-w-auto">
                <NavLink
                  className="flex gap-2 items-center"
                  to={`/profile/add/${token}`}
                >
                  <i class="fa-regular fa-pen-to-square"></i>
                  <button>Add Profile Data</button>
                </NavLink>
              </div>
            )}

            <div className="flex gap-2 bg-purple-600 rounded-md mb-4 p-2 items-center text-white font-semibold max-w-auto">
              <NavLink
                className="flex gap-2 items-center"
                to={`/profile/edit/${token}`}
              >
                <i class="fa-regular fa-pen-to-square"></i>
                <button>Edit Profile</button>
              </NavLink>
            </div>
          </div>

          {loading ? (
            <div
              role="status"
              className="flex gap-3 flex-col items-center justify-center mt-10"
            >
                <ReactLoading
              type="spinningBubbles"
              color="#A352F5"
              height={100}
              width={100}
            />
            <span class="text-center dark:text-white text-lg">
              Loading Profile Data...
            </span>
            </div>
          ) : (
            <div className="grid grid-cols-2 sm:grid-cols-12 gap-6 px-4">
              <div className="col-span-4 sm:col-span-3">
                <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
                  <div className="flex flex-col items-center">
                    <img
                      src={profile?.profilePic}
                      className="w-32 h-32 bg-gray-300 rounded-full mb-4 object-cover shrink-0"
                    ></img>
                    <h1 className="text-xl font-bold dark:text-white">
                      {profile?.name}
                    </h1>
                    <p className="text-gray-700 dark:text-gray-400">
                      {profile?.designation}
                    </p>
                    <div className="mt-6 flex flex-wrap gap-4 justify-center">
                      <a
                        target="_blank"
                        href={profile?.portfolio_website}
                        className="bg-purple-500 hover:bg-purple-600 text-white py-2 px-4 rounded"
                      >
                        Portfolio
                      </a>
                      <a
                        href={profile?.resume_url}
                        target="_blank"
                        className="bg-gray-300 hover:bg-gray-400 text-gray-700 py-2 px-4 rounded"
                      >
                        Resume
                      </a>
                    </div>
                  </div>
                  <hr className="my-6 border-t border-gray-300" />
                  <div className="flex flex-col">
                    <span className="text-gray-700 uppercase font-bold tracking-wider mb-2 dark:text-white">
                      General Information
                    </span>
                    <ul className="dark:text-gray-400">
                      <li className="mb-2">Age : {profile?.age}</li>
                      <li className="mb-2">Dob : {profile?.dob}</li>
                      <li className="mb-2">Gender : {profile?.gender}</li>
                      <li className="mb-2">Location : {profile?.location}</li>
                    </ul>
                  </div>
                  <hr className="my-6 border-t border-gray-300" />

                  <div>
                    <span className="text-gray-700 uppercase font-bold tracking-wider mb-2 dark:text-white">
                      Education
                    </span>
                    <p className="dark:text-gray-400">{profile?.education}</p>
                  </div>
                </div>
              </div>

              <div className="col-span-4 sm:col-span-9">
                <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
                  <h2 className="text-xl font-bold mb-4 dark:text-white">
                    About Me
                  </h2>
                  <p className="text-gray-700 dark:text-gray-400">
                    {profile?.profile_description}
                  </p>

                  <h2 className="text-xl font-bold mt-6 mb-4 dark:text-white">
                    Experience
                  </h2>

                  {/* For Showing the Experience */}
                  {userExp.map((item) => (
                    <div className="mb-6" key={item?._id}>
                      <div className="flex justify-between flex-wrap gap-2 w-full">
                        <span className="text-gray-700 font-bold dark:text-white">
                          {item?.role}
                        </span>
                        <p>
                          <span className="text-gray-700 mr-2 dark:text-gray-400">
                            at {item?.company}
                          </span>
                          <span className="text-gray-700 font-medium dark:text-white">
                            {item?.startDate} - {item?.endDate}
                          </span>
                        </p>
                      </div>
                      <p className="mt-2 dark:text-gray-400">
                        {item?.description}
                      </p>
                    </div>
                  ))}

                  <h3 className="font-semibold text-center mt-3 -mb-2 dark:text-purple-500">
                    Find me on
                  </h3>
                  <div className="flex justify-center items-center gap-6 my-6">
                    <a
                      className="text-gray-700 dark:text-gray-400 hover:text-purple-600"
                      aria-label="Visit TrendyMinds LinkedIn"
                      href={profile?.linkedin_url}
                      target="_blank"
                    >
                      <i class="fa-brands fa-linkedin text-xl"></i>
                    </a>
                    <a
                      className="text-gray-700 dark:text-gray-400 hover:text-purple-600"
                      aria-label="Visit TrendyMinds YouTube"
                      href=""
                      target="_blank"
                    >
                      <i class="fa-brands fa-youtube text-xl"></i>
                    </a>
                    <a
                      className="text-gray-700 dark:text-gray-400 hover:text-purple-600"
                      aria-label="Visit TrendyMinds Facebook"
                      href=""
                      target="_blank"
                    >
                      <i class="fa-brands fa-square-facebook text-xl"></i>
                    </a>
                    <a
                      className="text-gray-700 dark:text-gray-400 hover:text-purple-600"
                      aria-label="Visit TrendyMinds Instagram"
                      href={profile?.instagram_url}
                      target="_blank"
                    >
                      <i class="fa-brands fa-instagram text-xl"></i>
                    </a>
                    <a
                      className="text-gray-700 dark:text-gray-400 hover:text-purple-600"
                      aria-label="Visit TrendyMinds Twitter"
                      href={profile?.github_url}
                      target="_blank"
                    >
                      <i class="fa-brands fa-square-github text-xl"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
