import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../App";
import ThemeToggle from "../utils/ThemeToggle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

const Header = () => {
  const navigate = useNavigate();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const token = Cookies.get("user");
  const profilePicUrl = Cookies.get("userProfile");

  // handle Logout Function
  const handleLogout = () => {
    const url = `${baseUrl}/api/v1/auth/logout`;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        Cookies.remove("user");
        Cookies.remove("userProfile");
        toast.success(response.data.message);
        navigate("/login");
      })
      .catch((error) => {
        console.log("Error while running the logout Function ", error);
      });
  };

  useEffect(() => {
    // Close dropdown if clicked outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="">
      <nav className="border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-900 dark:text-white">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <NavLink to="/" className="flex items-center">
            <img
              src="Assets/Prince_Sahni_logo.png"
              className="mr-3 rounded-lg h-7 md:8 lg:10 object-cover"
              alt="Prince Sahni Portfolio Logo"
            />
            <span className="self-center text-sm md:text-md lg:text-lg font-semibold whitespace-nowrap">
              Prince Sahni.
            </span>
          </NavLink>

          {token ? (
            <div className="flex items-center lg:order-2 gap-2 md:gap-2">
              {/* Theme Toggle Button  */}
              <ThemeToggle />

              <button
                onClick={handleLogout}
                className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-2 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800"
              >
                Logout
              </button>
              <NavLink to="/profile">
                <img
                  className="w-8 h-8 md:h-10 md:w-10 rounded-full object-cover border border-purple-500"
                  src={
                    profilePicUrl
                      ? profilePicUrl
                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                  }
                  alt="Your Profile Pic"
                />
              </NavLink>

              <button
                onClick={handleMobileMenuToggle}
                data-collapse-toggle="mobile-menu-2"
                type="button"
                className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="mobile-menu-2"
                aria-expanded={isMobileMenuOpen}
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <svg
                  className="hidden w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          ) : (
            <div className="flex items-center lg:order-2 gap-2 lg:gap-2">
              {/* Theme Toggle Button  */}
              <ThemeToggle />

              <NavLink
                to="/login"
                className="text-gray-800 dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-2 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
              >
                Log in
              </NavLink>
              <NavLink
                to="/register"
                className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-2 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800"
              >
                Get started
              </NavLink>
              <button
                onClick={handleMobileMenuToggle}
                data-collapse-toggle="mobile-menu-2"
                type="button"
                className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="mobile-menu-2"
                aria-expanded={isMobileMenuOpen}
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <svg
                  className="hidden w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          )}

          <div
            className={`${
              isMobileMenuOpen ? "block" : "hidden"
            } justify-between items-center w-full lg:flex lg:w-auto lg:order-1`}
            id="mobile-menu-2"
          >
            <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
              <li>
                <NavLink
                  to="/"
                  className="block py-2 pr-4 pl-3 text-white rounded bg-purple-700 lg:bg-transparent lg:text-purple-700 lg:p-0 dark:text-white"
                  aria-current="page"
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/youtube"
                  className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Youtube
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/resource"
                  className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Resources
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/articles"
                  className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Articles
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/courses"
                  className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Courses
                </NavLink>
              </li>

              <li>
                <button
                  onClick={handleDropdownToggle}
                  id="dropdownDefault"
                  data-dropdown-toggle="dropdown"
                  className="flex items-center w-full py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Explore{" "}
                  <svg
                    className="w-2.5 h-2.5 ms-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>

                {isDropdownOpen && (
                  <div
                    ref={dropdownRef}
                    id="dropdown"
                    className="absolute z-10 bg-white dark:bg-gray-800 border border-gray-200 w-72 dark:border-gray-700 rounded-lg shadow-lg mt-2"
                  >
                    <ul
                      className="py-2 text-gray-700 dark:text-gray-200"
                      aria-labelledby="dropdownLargeButton"
                    >
                      <li>
                        <NavLink to="/webapps">
                          <div className="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                            <div class="ms-2">
                              <label
                                for="helper-radio-4"
                                class="font-medium text-gray-900 dark:text-gray-300"
                              >
                                <div className="text-yellow-400">
                                  Web Apps{" "}
                                  <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">
                                    Popular
                                  </span>
                                </div>
                                <p
                                  id="helper-radio-text-4"
                                  class="text-sm font-normal text-gray-500 dark:text-gray-300"
                                >
                                  You can see all the web apps which is
                                  deployed.
                                </p>
                              </label>
                            </div>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/androidApps">
                          <div className="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                            <div class="ms-2">
                              <label
                                for="helper-radio-4"
                                class="font-medium text-gray-900 dark:text-gray-300"
                              >
                                <div className="text-purple-400">
                                  Android Apps{" "}
                                  <span class="bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">
                                    Popular
                                  </span>
                                </div>
                                <p
                                  id="helper-radio-text-4"
                                  class="text-sm font-normal text-gray-500 dark:text-gray-300"
                                >
                                  You can see all the Android apps which is
                                  deploye
                                </p>
                              </label>
                            </div>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <a href="https://princesahniblog.site/" target="_blank">
                          <div className="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                            <div class="ms-2">
                              <label
                                for="helper-radio-4"
                                class="font-medium text-gray-900 dark:text-gray-300"
                              >
                                <div className="text-green-400">
                                  Prince Sahni Blog{" "}
                                  <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">
                                    New
                                  </span>
                                </div>

                                <p
                                  id="helper-radio-text-4"
                                  class="text-sm font-normal text-gray-500 dark:text-gray-300"
                                >
                                  Some helpful blogs on this website.
                                </p>
                              </label>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://projex.princesahni.com/"
                          target="_blank"
                        ></a>
                        <div className="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                          <div class="ms-2">
                            <label
                              for="helper-radio-4"
                              class="font-medium text-gray-900 dark:text-gray-300"
                            >
                              <div className="text-blue-500">
                                Projex{" "}
                                <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                                  Useful
                                </span>
                              </div>
                              <p
                                id="helper-radio-text-4"
                                class="text-sm font-normal text-gray-500 dark:text-gray-300"
                              >
                                Organize, track, and bring your project ideas to
                                life.
                              </p>
                            </label>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </li>

              <li>
                <NavLink
                  to="/contact"
                  className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        <ToastContainer autoClose={2000}></ToastContainer>
      </nav>
    </header>
  );
};

export default Header;
