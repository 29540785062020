import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { baseUrl } from "../../App";
import ReactLoading from "react-loading";



const Youtube = () => {
  // Make the State for Storing the Youtube Data
  const [youtube, setYoutube] = useState([]);
  const [loading, setLoading] = useState(true);

  // Call the Function to get the All the Data
  const getYoutube = async () => {
    try {
      const url = `${baseUrl}/api/v1/youtube/get`;
      await axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          // console.log(
          //   "Getting response while fetching youtube data ",
          //   response
          // );

          const sortedYoutube = response.data.data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          setYoutube(sortedYoutube);
        })
        .catch((error) => {
          console.log("Error while fetching youtube data ", error);
        });
    } catch (error) {
      console.log("Error while fetching youtube data ", error);
    } finally {
      setLoading(false);
    }
  };

  // Use the useEffect hook to automatically call the function
  useEffect(() => {
    getYoutube();
  }, []);

  return (
    <div>
      <div className="container px-6 py-12 mx-auto mt-10 mb-10">
        <h1 className="text-center font-bold text-2xl mb-5 text-purple-500 dark:text-purple-400">
          Explore Some Useful YouTube Tutorials
        </h1>
        <p className="font-medium text-center m-auto mb-10 dark:text-gray-300">
          Dive into the collection of educational videos designed to enhance
          your programming skills. Each tutorial covers key concepts and
          techniques to help you learn effectively and apply your knowledge with
          confidence.
        </p>

        {loading ? (
          <div
            role="status"
            className="flex gap-3 flex-col items-center justify-center mt-10"
          >
            <ReactLoading
              type="spinningBubbles"
              color="#A352F5"
              height={100}
              width={100}
            />
            <span class="text-center dark:text-white text-lg">Loading...</span>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {youtube.map((item) => (
              <div
                key={item._id}
                className="flex flex-col p-2 bg-purple-50 dark:bg-gray-800 rounded-lg"
              >
                <NavLink to={`/fullYoutube/${item._id}`}>
                  <img
                    className="rounded-lg h-48 w-full object-cover"
                    src={item.thumbnail}
                    alt="Youtube Video Thumbnail"
                  />
                </NavLink>
                <div className="mt-2 flex-1">
                  <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {item.name}
                  </h5>
                  <p
                    className="mb-3 font-normal text-gray-700 dark:text-gray-400"
                    dangerouslySetInnerHTML={{
                      __html: item.description.slice(0, 100),
                    }}
                  />
                </div>
                <div>
                  <NavLink
                    to={`/fullYoutube/${item._id}`}
                    className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-purple-700 rounded-md hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
                  >
                    Watch Now
                    <svg
                      className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9"
                      />
                    </svg>
                  </NavLink>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Youtube;
