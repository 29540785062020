import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { baseUrl } from "../../App";
import ReactLoading from "react-loading";

const FullYoutube = () => {
  // Making the State for fullYoutube Details
  const [fullYoutube, setFullYouTube] = useState({});
  const [loading, setLoading] = useState(true);

  // Getting the Params YoutubeId
  const { youtubeId } = useParams();

  // Calling the Function to Get the Full Details of the Youtube\
  const getFullYoutube = async () => {
    try {
      const url = `${baseUrl}/api/v1/youtube/get/${youtubeId}`;
      await axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          // console.log("Response while getting the Response", response);
          setFullYouTube(response.data.data);
        })
        .catch((error) => {
          console.log("Error while getting the Full Youtube ", error);
        });
    } catch (error) {
      console.log("Error while getting the Full Youtube ", error);
    } finally {
      setLoading(false);
    }
  };

  // Use useEffect hook to automatically call the function
  useEffect(() => {
    getFullYoutube();
  }, []);

  return (
    <div>
      {loading ? (
        <div
          role="status"
          className="flex gap-3 flex-col items-center justify-center mt-10"
        >
          <ReactLoading
            type="spinningBubbles"
            color="#A352F5"
            height={100}
            width={100}
          />
          <span class="text-center dark:text-white text-lg">
            Loading Full Youtube data...
          </span>
        </div>
      ) : (
        <div className="w-full sm:w-4/5 md:w-2/3 lg:w-1/2 px-6 py-12 mx-auto mt-20 mb-20 flex flex-col items-center justify-center">
          <iframe
            src={`${fullYoutube.link}`}
            className="border border-dashed w-full rounded-2xl mb-5 h-96"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen="allowfullscreen"
          ></iframe>
          <h1 className="font-bold text-xl mt-2 mb-5 dark:text-white text-center">
            {fullYoutube.name}
          </h1>
          <p
            className="text-gray-600 text-justify dark:text-gray-400"
            dangerouslySetInnerHTML={{
              __html: fullYoutube.description,
            }}
          />
          <p className="text-start font-bold text-gray-700 dark:text-gray-200 mt-5">
            Published by : {fullYoutube?.userId?.username}
          </p>
        </div>
      )}
    </div>
  );
};

export default FullYoutube;
