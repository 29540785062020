import React from "react";
import Routes from "./routes/router";

// export const baseUrl = "http://localhost:5000";
export const baseUrl = "https://portfolio-app-back.onrender.com";

function App() {
  return (
      <div className="dark:bg-gray-900">
        <Routes />
      </div>
  );
}

export default App;
