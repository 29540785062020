import React from "react";
import { NavLink } from "react-router-dom";

const About = () => {
  return (
    <div>
      <section className="container px-6 py-12 mx-auto mt-20">
        <div className="flex flex-col justify-center items-center">
          <h1 className="text-4xl font-bold text-center mb-5 text-purple-500 dark:text-purple-400">
            About Me
          </h1>
          <p className="font-medium text-lg text-center text-gray-500 dark:text-gray-400 m-auto w-full sm:w-2/3 md:1/2">
            I’m Prince Kumar Sahni a B.Tech Computer Science graduate with a
            passion for technology and education. My mission is to share
            knowledge and resources that empower others to excel in programming
            and technology. Through this platform, I aim to provide high-quality
            educational content that supports learners at every stage of their
            journey.
          </p>

          <div className="mt-4">
            <NavLink to="/resume">
            <button
              type="button"
              class="text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 me-2 mb-2"
            >
              <svg
                class="w-4 h-4 me-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 10V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1v6M5 19v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-1M10 3v4a1 1 0 0 1-1 1H5m14 9.006h-.335a1.647 1.647 0 0 1-1.647-1.647v-1.706a1.647 1.647 0 0 1 1.647-1.647L19 12M5 12v5h1.375A1.626 1.626 0 0 0 8 15.375v-1.75A1.626 1.626 0 0 0 6.375 12H5Zm9 1.5v2a1.5 1.5 0 0 1-1.5 1.5v0a1.5 1.5 0 0 1-1.5-1.5v-2a1.5 1.5 0 0 1 1.5-1.5v0a1.5 1.5 0 0 1 1.5 1.5Z"
                />
              </svg>
              My Resume
            </button>
            </NavLink>
           
            <a href="https://github.com/mrprince123" target="_blank">
              <button
                type="button"
                class="text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 me-2 mb-2"
              >
                <svg
                  class="w-4 h-4 me-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 .333A9.911 9.911 0 0 0 6.866 19.65c.5.092.678-.215.678-.477 0-.237-.01-1.017-.014-1.845-2.757.6-3.338-1.169-3.338-1.169a2.627 2.627 0 0 0-1.1-1.451c-.9-.615.07-.6.07-.6a2.084 2.084 0 0 1 1.518 1.021 2.11 2.11 0 0 0 2.884.823c.044-.503.268-.973.63-1.325-2.2-.25-4.516-1.1-4.516-4.9A3.832 3.832 0 0 1 4.7 7.068a3.56 3.56 0 0 1 .095-2.623s.832-.266 2.726 1.016a9.409 9.409 0 0 1 4.962 0c1.89-1.282 2.717-1.016 2.717-1.016.366.83.402 1.768.1 2.623a3.827 3.827 0 0 1 1.02 2.659c0 3.807-2.319 4.644-4.525 4.889a2.366 2.366 0 0 1 .673 1.834c0 1.326-.012 2.394-.012 2.72 0 .263.18.572.681.475A9.911 9.911 0 0 0 10 .333Z"
                    clip-rule="evenodd"
                  />
                </svg>
                My Github
              </button>
            </a>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mx-auto mt-20 gap-10">
          {/* Card  */}
          <div className="flex flex-col justify-around w-2/3 m-auto gap-10">
            <div>
              <div className="flex gap-2 items-center justify-end">
                <h1 className="font-bold text-xl dark:text-white">
                  Programmer
                </h1>
                <span class="inline-block p-3 text-purple-500 rounded-lg bg-purple-100/80 dark:bg-gray-700">
                  <svg
                    class="w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m8 8-4 4 4 4m8 0 4-4-4-4m-2-3-4 14"
                    />
                  </svg>
                </span>
              </div>
              <p className="text-end text-gray-500 dark:text-gray-400 mt-2">
                I write efficient, scalable code to solve complex problems and
                create innovative software solutions.
              </p>
            </div>
            <div>
              <div className="flex gap-2 items-center justify-end">
                <h1 className="font-bold text-xl dark:text-white">Creator</h1>
                <span class="inline-block p-3 text-purple-500 rounded-lg bg-purple-100/80 dark:bg-gray-700">
                  <svg
                    class="w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 6.03v13m0-13c-2.819-.831-4.715-1.076-8.029-1.023A.99.99 0 0 0 3 6v11c0 .563.466 1.014 1.03 1.007 3.122-.043 5.018.212 7.97 1.023m0-13c2.819-.831 4.715-1.076 8.029-1.023A.99.99 0 0 1 21 6v11c0 .563-.466 1.014-1.03 1.007-3.122-.043-5.018.212-7.97 1.023"
                    />
                  </svg>
                </span>
              </div>
              <p className="text-end text-gray-500 dark:text-gray-400 mt-2">
                I create educational content to help others learn and grow in
                technology, making complex topics accessible.
              </p>
            </div>
          </div>

          {/* Image  */}
          <div className="flex items-center bg-purple-300 rounded-t-full">
            <img
              className=""
              src="https://res.cloudinary.com/sniperdocs/image/upload/v1723741724/Prince_Pic_Portfolio_Edited_qmsksz.png"
              alt="My Image"
            />
          </div>

          {/* Card  */}
          <div className="flex flex-col justify-around w-2/3 m-auto gap-10">
            <div className="">
              <div className="flex gap-2 items-center justify-start">
                <span class="inline-block p-3 text-purple-500 rounded-lg bg-purple-100/80 dark:bg-gray-700">
                  <svg
                    class="w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-width="2"
                      d="M4.37 7.657c2.063.528 2.396 2.806 3.202 3.87 1.07 1.413 2.075 1.228 3.192 2.644 1.805 2.289 1.312 5.705 1.312 6.705M20 15h-1a4 4 0 0 0-4 4v1M8.587 3.992c0 .822.112 1.886 1.515 2.58 1.402.693 2.918.351 2.918 2.334 0 .276 0 2.008 1.972 2.008 2.026.031 2.026-1.678 2.026-2.008 0-.65.527-.9 1.177-.9H20M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </span>
                <h1 className="font-bold text-xl dark:text-white">
                  Web Development
                </h1>
              </div>
              <p className="text-start text-gray-500 dark:text-gray-400 mt-2">
                I build responsive, user-friendly websites that are visually
                appealing and functionally robust.
              </p>
            </div>
            <div className="">
              <div className="flex gap-2 items-center justify-start">
                <span class="inline-block p-3 text-purple-500 rounded-lg bg-purple-100/80 dark:bg-gray-700">
                  <svg
                    class="w-5 h-5 "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 15h12M6 6h12m-6 12h.01M7 21h10a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1Z"
                    />
                  </svg>
                </span>
                <h1 className="font-bold text-xl dark:text-white">
                  Android Development
                </h1>
              </div>
              <p className="text-start text-gray-500 dark:text-gray-400 mt-2">
                I develop mobile apps that deliver seamless, intuitive
                experiences tailored to users' needs.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
