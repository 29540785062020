import axios from "axios";
import React, { useState, useEffect } from "react";
import { baseUrl } from "../../App";
import ReactLoading from "react-loading";

const NewTestimonial = () => {
  // Make the State
  const [testimonial, setTestimonial] = useState([]);
  const [loading, setLoading] = useState(true);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  const token = getCookie("authToken");
  // console.log("Token", token);

  // Make the Function
  const getTestimonial = async () => {
    try {
      const url = `${baseUrl}/api/v1/testimonial/get`;
      await axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        })
        .then((response) => {
          // console.log("Reponse while getting Testimonial Data ", response);

          const sortedTestimonial = response.data.data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          setTestimonial(sortedTestimonial);
        })
        .catch((error) => {
          console.log("Error while getting the Testimonial Data ", error);
        });
    } catch (error) {
      console.log("Error while getting the Testimonial Data ", error);
    } finally {
      setLoading(false);
    }
  };

  // use the useEffect Hook to automatically call the function
  useEffect(() => {
    getTestimonial();
  }, []);

  return (
    <div>
      {loading ? (
        <div
          role="status"
          className="flex gap-3 flex-col items-center justify-center mt-10"
        >
          <ReactLoading
            type="spinningBubbles"
            color="#A352F5"
            height={100}
            width={100}
          />
          <span class="text-center dark:text-white text-lg">
            Loading Testimonial Data...
          </span>
        </div>
      ) : (
        <div
          class="text-gray-600 dark:text-gray-300 pt-8 dark:bg-gray-900"
          id="reviews"
        >
          <div class="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
            <h2 className="text-purple-500 uppercase font-medium text-center mb-2">
              TESTIMONIAL
            </h2>
            <h2 className="font-bold text-2xl text-center dark:text-white">
              What our People Says
            </h2>
            <p className="font-medium text-gray-600 dark:text-gray-400 m-auto text-center w-full sm:w-2/3 mt-2">
              Discover inspiration and insights through recent reviews from our
              People. Their success stories reflect the transformative journey
              of learning and growth with Portfolio.
            </p>

            <div class="md:columns-2 lg:columns-3 gap-8 space-y-8 mt-10">
              {testimonial.map((item) => (
                <div key={item._id} class="relative group">
                  <div class="absolute transition rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 blur duration-400 group-hover:opacity-100 group-hover:duration-200"></div>

                  <div class="relative p-6 space-y-6 leading-none rounded-lg bg-slate-100 dark:bg-slate-800 ring-1 ring-gray-900/5">
                    <div class="flex items-center space-x-4">
                      <img
                        class="w-12 h-12 rounded-full object-cover"
                        src={item.profilePic}
                        alt="user avatar"
                        width="400"
                        height="400"
                        loading="lazy"
                      />
                      <div>
                        <h3 class="text-lg font-semibold text-black dark:text-white">
                          {item.name}
                        </h3>
                        <p class="text-gray-500 text-md">{item.designation}</p>
                      </div>
                    </div>
                    <p class="leading-normal text-gray-500 dark:text-gray-300 text-md">
                      {item.message}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewTestimonial;
