import React from "react";

const NewsLetter = () => {
  const devGoingOn = () => {
    alert("Wait!, Development is going on");
  };

  return (
    <div className="w-4/5 mx-auto flex flex-col lg:flex-row gap-10 justify-between mt-20 p-10 rounded-2xl bg-purple-50 dark:bg-gray-800">
      <div className="w-full lg:w-1/3">
        <h2 className="text-2xl lg:text-3xl text-slate-800 dark:text-white font-bold">
          Subscribe to our newsletter
        </h2>
        <p className="text-base lg:text-xl font-medium text-gray-500 dark:text-gray-400 mt-4">
          Stay informed with the latest trends, insights, and updates in coding
          and technology. Join our growing community of developers and never
          miss a beat on the most important topics.
        </p>
        <div className="flex flex-col sm:flex-row gap-4 mt-6">
          <input
            className="flex-1 rounded-lg border border-gray-400 bg-transparent text-slate-800 dark:text-white placeholder-gray-400 focus:outline-none p-2"
            type="text"
            placeholder="Enter your email"
          />
          <button
            onClick={() => devGoingOn()}
            className="text-white bg-purple-500 p-2 rounded-lg"
          >
            Subscribe
          </button>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-6 w-full lg:w-1/2">
        <div className="flex-1">
          <div className="bg-purple-100 dark:bg-gray-600 p-2 rounded-lg inline-flex items-center justify-center">
            <svg
              className="w-6 h-6 text-purple-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Zm3-7h.01v.01H8V13Zm4 0h.01v.01H12V13Zm4 0h.01v.01H16V13Zm-8 4h.01v.01H8V17Zm4 0h.01v.01H12V17Zm4 0h.01v.01H16V17Z"
              />
            </svg>
          </div>

          <h3 className="text-lg lg:text-xl font-semibold text-purple-400 dark:text-white mt-4">
            Weekly articles
          </h3>
          <p className="text-gray-600 dark:text-gray-400 text-sm lg:text-lg mt-2">
            Get exclusive access to our weekly articles that cover coding tips,
            project ideas, and insights from industry experts. Learn and grow
            with our expertly crafted content.
          </p>
        </div>

        <div className="flex-1">
          <div className="bg-purple-100 dark:bg-gray-600 p-2 rounded-lg inline-flex items-center justify-center">
            <svg
              className="w-6 h-6 text-purple-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
              />
            </svg>
          </div>

          <h3 className="text-lg lg:text-xl font-semibold text-purple-400 dark:text-white mt-4">
            No Spam
          </h3>
          <p className="text-gray-600 dark:text-gray-400 text-sm lg:text-lg mt-2">
            We value your privacy. Receive only curated, relevant information in
            your inbox—no unnecessary emails, no spam.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
