import React from "react";

const NewMarquee = () => {
  return (
    <div className="mt-40 mb-40">
      <div className="w-5/6 m-auto sm:w-3/4">
        <h2 className="uppercase text-center text-md font-semibold text-purple-600">
          Join a Thriving Community
        </h2>
        <p className="text-center text-2xl font-bold mt-2 dark:text-white">
          Who is growing with Us
        </p>
        <p className="text-center mt-4 dark:text-gray-400">
          Our platform is trusted by a diverse group of learners, from beginners
          taking their first steps in coding to professionals enhancing their
          skills. Join thousands who are advancing their careers and achieving
          their goals with our curated resources and expert guidance
        </p>

        <marquee>
          <div className="flex items-center justify-center gap-24 mt-6">
            <div className="flex items-center gap-4">
              <img
                className="h-14 w-14 rounded-lg"
                src="https://res.cloudinary.com/sniperdocs/image/upload/v1733665631/Cook_Book_Final_Logo_daxcbk.png"
                alt=""
              />
              <h4 className="text-slate-800 font-bold text-xl dark:text-white">Cook Book</h4>
            </div>

            <div className="flex items-center gap-4">
              <img
                className="h-14 w-14 rounded-lg"
                src="https://res.cloudinary.com/sniperdocs/image/upload/v1724862828/studio_uls7ym.png"
                alt=""
              />
              <h4 className="text-slate-800 font-bold text-xl dark:text-white">Arduino Pro</h4>
            </div>

            <div className="flex items-center gap-4">
              <img
                className="h-14 w-14 rounded-lg"
                src="https://res.cloudinary.com/sniperdocs/image/upload/v1724862820/browser_x_uusfow.png"
                alt=""
              />
              <h4 className="text-slate-800 font-bold text-xl dark:text-white">Browser X</h4>
            </div>

            <div className="flex items-center gap-4">
              <img
                className="h-14 w-14 rounded-lg"
                src="https://res.cloudinary.com/sniperdocs/image/upload/v1733666086/black_projex_ods0el.png"
                alt=""
              />
              <h4 className="text-slate-800 font-bold text-xl dark:text-white">Projex</h4>
            </div>

            <div className="flex items-center gap-4">
              <img
                className="h-14 w-14 rounded-lg"
                src="https://res.cloudinary.com/sniperdocs/image/upload/v1720546359/xy3e8w5v0jai4su2wepn.png"
                alt=""
              />
              <h4 className="text-slate-800 font-bold text-xl dark:text-white">Prince Corp</h4>
            </div>
          </div>
        </marquee>
      </div>
    </div>
  );
};

export default NewMarquee;
