import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../../App";
import ReactLoading from "react-loading";



const Resource = () => {
  // Make the State for the Resource
  const [resources, setResource] = useState([]);
  const [loading, setLoading] = useState(true);

  // Make the Function call
  const getAllResources = async () => {
    try {
      const url = `${baseUrl}/api/v1/resource/get`;
    await  axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          // console.log("Resource while getting all the Resources ", response);

          const sortedYoutube = response.data.data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          setResource(sortedYoutube);
        })
        .catch((error) => {
          console.log("Error while getting all the Resource ", error);
        });
    } catch (error) {
      console.log("Error while getting the Resources ", error);
    } finally {
      console.log("working");
      setLoading(false);
    }
  };

  // Make the Use State
  useEffect(() => {
    getAllResources();
  }, []);

  return (
    <div>
      <div className="container px-6 py-12 mx-auto mt-10 mb-10">
        <h1 className="text-center font-bold text-2xl mb-5 text-purple-500 dark:text-purple-400">
          Explore Some Comprehensive Resources
        </h1>
        <p className="font-medium text-center  m-auto mb-10 dark:text-gray-300">
          Browse through a diverse range of educational materials, including
          in-depth courses and detailed documents. Our resources are crafted to
          provide you with thorough knowledge and practical skills in
          programming and technology.
        </p>

        {loading ? (
          <div
            role="status"
            className="flex gap-3 flex-col items-center justify-center mt-10"
          >
           <ReactLoading
              type="spinningBubbles"
              color="#A352F5"
              height={100}
              width={100}
            />
            <span class="text-center dark:text-white text-lg">Loading...</span>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {resources.map((item) => (
              <div
                key={item._id}
                className="bg-pink-50 dark:bg-gray-800 rounded-lg flex flex-col p-2"
              >
                <p
                  className={`p-2 w-max text-xs mb-2 rounded-md text-white font-medium ${
                    item.title === "Android"
                      ? "bg-green-500"
                      : item.title === "Web Development"
                      ? "bg-yellow-500"
                      : item.title === "AI/ML"
                      ? "bg-blue-500"
                      : item.title === "DSA"
                      ? "bg-red-500"
                      : item.title === "Programming"
                      ? "bg-sky-500"
                      : item.title === "Video Editing"
                      ? "bg-purple-500"
                      : ""
                  }`}
                >
                  {item.title}
                </p>

                <img
                  className="h-48 object-cover rounded-lg mb-2"
                  src={item.courseBanner}
                  alt=""
                />
                <div className="flex flex-col justify-between">
                  <p className="font-bold text-lg mb-2 dark:text-white">
                    {item.name}
                  </p>
                  <p className="text-gray-500 mb-4 dark:text-gray-400">
                    {item.description.slice(0, 200)}...
                  </p>

                  <div className="flex justify-between items-center">
                    <a
                      href={item.courseLink}
                      target="_blank"
                      className="bg-purple-600 text-sm p-2 text-white rounded-md font-medium hover:bg-purple-700"
                    >
                      Go To Link
                    </a>
                    <p className="text-slate-700 font-medium dark:text-gray-400">
                      #{item.company}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Resource;
