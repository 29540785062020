import React from "react";
import Testimonial from "./testimonial";
import Hero from "./hero";
import Service from "./service";
import Service2 from "./service2";
import Service3 from "./service3";
import Faqs from "./faqs";
import NewHero from "./newHero";
import Marquee from "./marquee";
import NewMarquee from "./newMarquee";
import NewTestimonial from "./NewTestimonial";
import NewsLetter from "./NewsLetter";

const Home = () => {
  return (
    <div>
      <NewHero />
      <Hero />
      <Service />
      <Service3 />
      <NewMarquee />
      <Service2 />
      <NewTestimonial />
      <Faqs />
      <NewsLetter />
    </div>
  );
};

export default Home;
